import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Div from "app/components/Div";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useDispatch } from "react-redux";
import { BillingPeriodUnit, Plan } from "services/graphql";
import { useFamilyFriendlyFlag } from "hooks/useFeatureFlags";
import { setSelectedPlan } from "modules/checkout";
import { useCheckout } from "modules/selectors";
import useUserSubscription from "hooks/Subscriptions/useUserSubscription";
import { Redirect, useHistory, useLocation } from "react-router";
import { Routes } from "constants/routes";
import { useTieredPlans, usePlans } from "hooks/Checkout";
import { CloseCircle } from "app/components/Icon";
import { useUserHasTrialed } from "hooks/Subscriptions/useUserHasTrialed";
import { getStoredGCLID } from "hooks/useGetGclid";
import { checkoutViewCheckout } from "services/typewriter/segment";
import { PlanSelect } from "./PlanSelect";
import { CheckoutFlow } from "./CheckoutFlow";
import styles from "./shared-styles.module.scss";

const Checkout = () => {
  const location = useLocation();
  const history = useHistory();
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();
  const dispatch = useDispatch();
  const { hasTrialed } = useUserHasTrialed();

  const [isSelectingPlan, setIsSelectingPlan] = useState(true);
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(
    BillingPeriodUnit.Year
  );
  const {
    data: plansData,
    error: errorPlans,
    loading: fetchingPlans,
  } = usePlans();
  const { plans } = plansData || {};
  const { tieredPlans } = useTieredPlans({
    plans,
    billingPeriodUnit: selectedBillingPeriod,
  });
  const { selectedPlan } = useCheckout();
  const selectPlan = (plan: Plan) => dispatch(setSelectedPlan(plan));

  const displayedPlans = tieredPlans
    ? Object.keys(tieredPlans)
        .map(key => tieredPlans[key as any])
        .sort((a, b) => a.priceInCents - b.priceInCents)
    : [];

  useEffect(() => {
    if (!displayedPlans) {
      return;
    }

    // @TODO: Best value on the back-end is returning true if plan is yearly, might need to update to return true only if standard or premium
    const bestValuePlan = displayedPlans
      .filter(plan => plan.isBestValue)
      .sort((a, b) => b.priceInCents - a.priceInCents)[0];
    dispatch(setSelectedPlan(bestValuePlan));
  }, [plans]);

  useEffect(() => {
    checkoutViewCheckout({
      gclid: getStoredGCLID(),
      module: "Checkout",
      location: location.pathname,
    });
  }, []);

  /**
   * Find the plan that matches the previous selected plan's access type
   */
  useEffect(() => {
    if (!selectedPlan) {
      return;
    }

    if (displayedPlans.length === 0) {
      return;
    }

    const isSelectedPlanDisplayed = displayedPlans.some(
      plan => plan.id === selectedPlan.id
    );

    if (isSelectedPlanDisplayed) {
      return;
    }

    const newPlanToSelect = displayedPlans.find(
      plan => plan.accessType === selectedPlan.accessType
    );

    if (!newPlanToSelect) {
      const lastPlanIndex = displayedPlans.length - 1;
      selectPlan(displayedPlans[lastPlanIndex]); // Select last plan if no match
      return;
    }

    selectPlan(newPlanToSelect);
  }, [displayedPlans]);

  const [subscriptionData = {}, , userError] = useUserSubscription();

  useEffect(function checkUserSubscriptionOnMount() {
    const { me: userData } = subscriptionData;

    if (userData) {
      const { primarySubscription } = userData;
      if (primarySubscription?.isActive) {
        history.push(Routes.account__subscription);
      }
    }
  }, []);

  const loadingPlans = fetchingPlans || !(tieredPlans && selectedPlan);

  if (loadingPlans) {
    return <LoaderCentered />;
  }

  if (userError || errorPlans) {
    return <Redirect to="/not-found" />;
  }

  const oldHeader =
    selectedPlan.canTrial && !hasTrialed
      ? "Start your 7 day free trial"
      : "Start Your Dance Journey";
  const newHeader = "Access to STEEZY STUDIO & STEEZY FAMILY";
  const headerText = isFamilyFeatureEnabled ? newHeader : oldHeader;

  return (
    <Div>
      <Helmet title="Checkout | Online Dance Classes and Tutorials" />
      {isSelectingPlan ? (
        <>
          <CloseCircle
            className={styles.closeCircle}
            onClick={() => history.push(Routes.dashboard)}
          />

          <div className={styles.planSelectContainer}>
            <PlanSelect
              headerText={headerText}
              continueToCheckout={() => setIsSelectingPlan(false)}
              plans={displayedPlans}
              selectedPlan={selectedPlan}
              selectedBillingPeriod={selectedBillingPeriod}
              setSelectedBillingPeriod={setSelectedBillingPeriod}
            />
          </div>
        </>
      ) : (
        <CheckoutFlow
          headerText={headerText}
          setIsSelectingPlan={setIsSelectingPlan}
        />
      )}
    </Div>
  );
};

export default Checkout;
